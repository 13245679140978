import React from 'react';

const AlmondMilk = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="39.306"
    height="39.323"
    viewBox="0 0 39.306 39.323"
  >
    <defs>
      <clipPath>
        <rect width="39.306" height="39.323" fill="currentColor" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <path
        d="M33.371,0c.646.079,1.553.146,2.442.309A3.819,3.819,0,0,1,39.07,3.668a15.32,15.32,0,0,1,.116,4.623,49.083,49.083,0,0,1-7.173,20.361,28.243,28.243,0,0,1-5.8,7,15.326,15.326,0,0,1-8.87,3.616A15.629,15.629,0,0,1,4.823,34.784,15.338,15.338,0,0,1,.14,25.4,15.664,15.664,0,0,1,3.46,13.392,24.261,24.261,0,0,1,8.61,8.71,49.123,49.123,0,0,1,24.015,1.5,39.1,39.1,0,0,1,31,.15c.7-.065,1.4-.091,2.368-.15M5.582,32.024,34.25,3.356c-1.169.437-2.275.794-3.335,1.255A68.767,68.767,0,0,0,11.857,16.886a23.272,23.272,0,0,0-5.381,6.876,12.4,12.4,0,0,0-1.489,5.884,5.2,5.2,0,0,0,.594,2.378m30.377-26.9L7.266,33.818a16.369,16.369,0,0,0,1.691.45,9.646,9.646,0,0,0,4.847-.657,20.349,20.349,0,0,0,7.76-5.216A67.478,67.478,0,0,0,34.81,8.218c.429-.992.755-2.027,1.148-3.094m.69,4.956-.09-.028c-.059.12-.121.239-.177.361a71.389,71.389,0,0,1-12.532,18.94,24.042,24.042,0,0,1-8.063,5.933c-1.148.494-2.358.843-3.607,1.282.876.14,1.722.329,2.579.4a13.316,13.316,0,0,0,10.068-3.163A24.992,24.992,0,0,0,29.44,28.4a48.17,48.17,0,0,0,6.77-16.08c.17-.74.293-1.491.438-2.237M2.85,27.2c.155-.591.269-1.1.423-1.6a22.1,22.1,0,0,1,5.935-9.357,69.774,69.774,0,0,1,19.751-13.3c.109-.05.215-.111.322-.166a.791.791,0,0,0-.393-.018A48.208,48.208,0,0,0,18.625,5.807,40.2,40.2,0,0,0,7.2,12.793,13.753,13.753,0,0,0,2.3,23.761,12.7,12.7,0,0,0,2.85,27.2"
        transform="translate(0)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default AlmondMilk;
